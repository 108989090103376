import { ee } from "../../services/earth-engine";
import { mergeProperties, retrieveExtremes, getDate } from "../utils";
import {acquireFromDate as acquireFromDateLandSat} from "../../algorithms/satellite/landsat";
import { scoreCloudRatio } from "../imagery";
import * as Metadata from "../../common/metadata";

const addGridPosition = (element) => {
  const image = ee.Image(element);

  return image.set({ [Metadata.GRID_POSITION]: image.get("MGRS_TILE") });
};

const sliceByRevisit = (collection, startingDate, days) => {
  const start = ee.Date(startingDate).update(null, null, null, 0, 0, 0);
  const end = start.advance(days, "day");

  return collection.filterDate(start, end);
};


const getImageDate = function (image){
  var id = image.idString;
  if(image.idString === undefined){
    id = image.get('system:id').getInfo();
    console.error("Image without idString", image.getInfo(),"ID", id);
  } 
  var date = id.split("/");
  date = date[2].split("_"); //ee.String(date.get(2)).split("_");
  date  = date[0]; // date.get(0);
  let year = parseInt(date.substring(0,4)); 
  let monthIndex = parseInt(date.substring(4,6))-1;
  let day = parseInt(date.substring(6,8));
  var datetime = new Date(year,monthIndex,day);//ee.Date.parse('yyyyMMdd\'T\'HHmmss', date);

  return datetime;
} 

export const maskS2ImageMask = (img, mission) =>{
  var cloudBitMask = ee.Number(2).pow(10).int();
  var cirrusBitMask = ee.Number(2).pow(11).int();
  var qa = img.select(mission.bands.qa);
  var ma = qa.bitwiseAnd(cloudBitMask).eq(0).and(
          qa.bitwiseAnd(cirrusBitMask).eq(0));

  ma = ma.focal_min({kernel: ee.Kernel.circle({radius: 1}), iterations: 1});
  img = img.mask(ma);
  return img;
};

export const maskS2Clouds = (img,geometry,bandName, scale,imgDateParam) => {
      if(img.idString !== undefined || imgDateParam !== undefined){
    
          let imgDate = imgDateParam === undefined ? getImageDate(img) : imgDateParam;  
          let dt202201 = new Date(2022,1,1);//ee.Date('2022-02-01');//.lt(cloudThreshold)
          //console.log("imgDate", imgDate);
          if(imgDate !== undefined && imgDate.getTime !== undefined){ 
            let useDateGt202201 = dt202201.getTime() < imgDate.getTime();   
            if(useDateGt202201){          
              var cloudPercentageExists = ee.Algorithms.If(img.propertyNames().contains('CLOUDY_PIXEL_PERCENTAGE'), true, false);
              let propsExists = cloudPercentageExists.getInfo();
             //console.log("CLOUDY_PIXEL_PERCENTAGE is present:",propsExists); 
              if(propsExists){
                return  ee.Number(-1);// ee.Number(img.get('CLOUDY_PIXEL_PERCENTAGE')).divide(ee.Number(100));
              } 
            }
          }
      
      }
      
      var image = img; 
      var cloudBitMask = 1 << 10;
      var cirrusBitMask = 1 << 11;
      var qa = image.select(bandName); 
      var cloud = qa.bitwiseAnd(cloudBitMask).eq(0);
      //var cirrus = qa.bitwiseAnd(cirrusBitMask).eq(0);  // Maybe it's time to abandon the cirrus cloud filter???
      //var expr = cloud.or(cirrus);
      var expr = cloud; // only cloud filters 
      //var cloudFiltered = image.updateMask(expr);
      var cloudPixelArea = expr.multiply(ee.Image.pixelArea());
      var res = cloudPixelArea.reduceRegion({
        reducer: ee.Reducer.sum(),
        geometry: geometry,
        scale: scale,
        maxPixels: 1e9,
      });
    
      var geometryArea = ee.Number(geometry.area());
      var cloudyArea = res.get(bandName);
      //console.log("RATIO-DEBUG", cloudyArea);
      var ratio = ee.Number(cloudyArea).divide(geometryArea);//ee.Number(100).multiply()
      ratio  = ee.Number(1).subtract(ratio); //to-do validate
      return ratio;
};

export const acquireFromDate = (date, mission, geometry) => {

  if(mission.name.indexOf("LANDSAT")!==-1){
    return acquireFromDateLandSat(date, mission, geometry);
  }
  const slice = sliceByRevisit(
    ee.ImageCollection(mission.name).filterBounds(geometry),
    date,
    mission.cycle
  );
  //console.log("ACQUIRE FROM DATE - sentinel", mission);
  const mosaicked = slice.mosaic().clip(geometry);
  const bandName = "QA60";
  const scale = 60;
  const image = mosaicked.set(mergeProperties(slice));
  //console.log("MaskS2Cloud - for mosaic on ", date);
  const ratio = maskS2Clouds(image,geometry,bandName,scale, date);
  
  try{
   // console.log("CLOUDS-PERCENTAG-DEBUG",date,"image", image.getInfo(),"ratio" , ratio.getInfo());
    return image.set("CLOUDS", ratio);
  }catch(e){
    console.log("Error clouds", e);
    return image.set("CLOUDS", ee.Number(0));
  }
  
  //const ratio = scoreCloudRatio(maskedImage, bandName, geometry, scale);
  //return image.set("CLOUDS", ratio); 
};

// Computes a list of valid dates in the region to be retrieved with acquireFromDate.
export const processCollection = (mission, geometry) => {
  const query = ee.ImageCollection(mission.name).filterBounds(geometry);

  // Retrieve the globally extreme dates (earliest and latest)
  const global = retrieveExtremes(query);

  // Compute the grid position of each image and sort in ascending order
  const enhanced = query.map(addGridPosition).sort(Metadata.GRID_POSITION);

  // Retrieve the northeasternmost grid position within the specified bounds
  const northeasternPosition = ee
    .Image(enhanced.toList(1).get(0))
    .get(Metadata.GRID_POSITION);

  // Keep images in the slice where the satellite passed first (northeast)
  const filtered = enhanced.filter(
    ee.Filter.eq(Metadata.GRID_POSITION, northeasternPosition)
  );

  // Retrieve the extremes in the northeastern position
  const northeastern = retrieveExtremes(filtered);

  // Compute the difference in days between the earliest image in the
  // northeastern position and the globally earliest image
  const difference = northeastern.earliest
    .difference(global.earliest, "day")
    .abs();
  const remainder = ee.Number(difference).mod(mission.cycle);

  // The amount of days we need to go back is given by the reverse of the
  // difference, in terms of the duration of an orbit
  const step = ee.Number(mission.cycle).subtract(remainder);

  // Compute the date of the earliest possible image in the northeastern
  // position (whether or not it exists) by going back in time
  const earliestDate = global.earliest.advance(step.multiply(-1), "day"); // 1.21 gigawatts!

  // Compute the amount of complete orbital cycles between the earliest and
  // latest possible dates of the images in the northeastern position (whether
  // or not they exist)
  const completeCycles = ee
    .Number(earliestDate.difference(global.latest, "day").abs())
    .divide(mission.cycle)
    .ceil();

  // Generate slices of 5 (0, 5, 10, 15, ...), one for each complete cycle
  const additions = ee.List.sequence(0, null, mission.cycle, completeCycles);

  // Transform each slice into an empty image. If the slice contains at least
  // one image, we add metadata related to the correspondent orbital cycle,
  // to allow for filtering later
  const carriers = additions.map((increment) => {
    const startingDate = earliestDate.advance(increment, "day");
    const collection = sliceByRevisit(query, startingDate, mission.cycle);

    const empty = ee.Algorithms.IsEqual(collection.size(), 0);
    const properties = ee.Algorithms.If(empty, {}, mergeProperties(collection));

    return ee.Image(0).set(properties);
  });

  // Keep only the images whose combined geometries contain the AOI
  const valid = ee.ImageCollection.fromImages(carriers).filter(
    ee.Filter.contains(Metadata.FOOTPRINT, geometry)
  );

  // For now only the date of the slice is important.
  return ee.List(valid.toList(valid.size()).map(getDate));
};

export const generateCloudMap = (dates, mission, geometry) => {
  const cloudList = ee.List(dates).map((date) => {
    const image = ee.Image(acquireFromDate(date, mission, geometry));
    return image.get("CLOUDS");
  });

  return cloudList;
};

const queryAvailable = (mission) => (geometry) => {
  const query = processCollection(mission, geometry);
  // Format Data as ISO standart formating and and UTC
  const datesQuery = query.map((date) => ee.Date(date).format(null, 'UTC'));
  const cloudQuery = generateCloudMap(datesQuery, mission, geometry);

  return ee.Dictionary.fromLists(datesQuery, cloudQuery);
};

const getAvailable = (mission) => (geometry) => {
  const query = processCollection(mission, geometry);
  // Format Data as ISO standart formating and and UTC
  const datesQuery = query.map((date) => ee.Date(date).format(null, 'UTC'));
  const cloudQuery = generateCloudMap(datesQuery, mission, geometry);

  return ee.Dictionary.fromLists(datesQuery, cloudQuery);
};

const acquire = (mission) => (date, geometry) => {
  if(window.location.href.indexOf("bathymetry") !== -1) return false;
  console.log("PARAMS", date, mission, geometry);
  return acquireFromDate(date, mission, geometry);
};

const format = (properties) => {
  return properties["system:time_start"] + " -- " + properties["cloud"];
};

export default {
  queryAvailable,
  getAvailable,
  acquire,
  format,
};
