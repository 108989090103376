import React, { useEffect, useRef, useState } from "react"; 
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ee from "../../../services/earth-engine";

import { Box, Button, Checkbox, Typography } from "@material-ui/core";
import GoogleMap from "../map/GoogleMap";
import StepperButtons from "../acquisition/StepperButtons";
import * as MapGoogle from "../../../common/map";
import { Actions as Acquisition } from "../../../store/ducks/acquisition";
import TourGuider from "../tour/TourGuider";
import { useLocalStorage } from "../../../common/utils";
import HelpButton from "../core/HelpButton";
import { getMangroves } from "../../../algorithms/csqueeze";
import Alert from "@material-ui/lab/Alert";
import { get as getSatellite } from "../../../common/satellites";
import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { HelpOutlineOutlined } from "@material-ui/icons";
import {acquireFromDate as acquireFromDateSentinel, maskS2Clouds, maskS2ImageMask} from "../../../algorithms/satellite/sentinel";
import {acquireFromDate as acquireFromDateLandSat, maskLandsatImageMask, maskLandsatCloudsRatio} from "../../../algorithms/satellite/landsat";
import {  Bar, Chart } from "react-chartjs-2";
//import annotationPlugin from "chartjs-plugin-annotation";
 
//Chart.pluginService.register(annotationPlugin);
// useStyles is a hook for styling this component with Material UI's styling solution

// This is the page of the second step of the aquisition wizard
// it is supposed to get the Area of Interest (AOI) from the map
// and to save it in the storage.
// It also shows a button to start the third step.
const IntertidalZoneDefiner = ({ navigate, ...extra }) => {
  const dispatch = useDispatch();
    

  // get the current language
  const [t] = useTranslation();
  const [overlay, setOverlay] = useState(null);
  const [coordinates, setCoordinates] = useState(null);
  const [areaOfPolygon, setAreaOfPolygon] = useState(null);
  const [imagesLayers, setImagesLayers] = useState([]);
  const [manualThreshold, setManualThreshold] = useState(-1);
  const [layers, setLayers] = useState({});
  const dates = useSelector((state) =>state.acquisition.availableDates);

   let saveZoomChange = function () {
      let zoom=MapGoogle.getZoomLevel();
      if(typeof(zoom)!=="undefined" && zoom !=="undefined"){
        window.sessionStorage.setItem("zoom_level",zoom);
      }
   };
   window.sessionStorage.removeItem("zoom_level"); //clear previous saved zoom (aquisition -first use case)
   setInterval(saveZoomChange, 5000); 
  
   /////////// PARA BABITONGA
var img0 = ee.Image('COPERNICUS/S2_SR_HARMONIZED/20230320T132239_20230320T132815_T22JGS');
var img1 = ee.Image('COPERNICUS/S2_SR_HARMONIZED/20230330T132239_20230330T132858_T22JGS');
var img2 = ee.Image('COPERNICUS/S2_SR_HARMONIZED/20230404T132231_20230404T132232_T22JGS');
var img3 = ee.Image('COPERNICUS/S2_SR_HARMONIZED/20230419T132239_20230419T132235_T22JGS');
var img4 = ee.Image('COPERNICUS/S2_SR_HARMONIZED/20230514T132231_20230514T132233_T22JGS');
var img5 = ee.Image('COPERNICUS/S2_SR_HARMONIZED/20230519T132239_20230519T132237_T22JGS');
var img6 = ee.Image('COPERNICUS/S2_SR_HARMONIZED/20230603T132241_20230603T132236_T22JGS');
var img7 = ee.Image('COPERNICUS/S2_SR_HARMONIZED/20230608T132239_20230608T132237_T22JGS');
var img8 = ee.Image('COPERNICUS/S2_SR_HARMONIZED/20230628T132239_20230628T132237_T22JGS');
var img9 = ee.Image('COPERNICUS/S2_SR_HARMONIZED/20230713T132241_20230713T132606_T22JGS');
var img10 = ee.Image('COPERNICUS/S2_SR_HARMONIZED/20230807T132239_20230807T132237_T22JGS');


let markedPoints = {};
let mapRef = window.initMap;//useRef();
let visibleMap = document.getElementById("google-maps");
if(false){ /*visibleMap !== null*/
  console.log("Set visible map");
  let mapComponent = new window.google.maps.Map(visibleMap, {
    center: { lat: -26.285757, lng: -48.73506 }, 
    zoom: 13,
    scaleControl: true,
    streetViewControl: false,
    mapTypeControl: true,
    mapTypeControlOptions: {
      style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
      position: window.google.maps.ControlPosition.TOP_CENTER,
    },
    styles: [
      {
        featureType: "poi",
        stylers: [{ visibility: "off" }],
      },
    ],
    fullscreenControl: false,
  });
  mapRef =  mapComponent;
  mapRef.current = mapComponent;
}else{
  mapRef.current= mapRef;
}
 
let geometryThumb = JSON.parse(window.localStorage.getItem("AOIIntertidal")); 
const formatedCoordinates = geometryThumb.map((coord) => {
  return { lat: coord[1], lng: coord[0] };
});
console.log("formatedCoordinates",formatedCoordinates,geometryThumb);
let AOI = new window.google.maps.Polygon({
  path: formatedCoordinates,
  geodesic: true,
  fillColor: "transparent",
  strokeColor: "#00A391",
  strokeOpacity: 1,
  strokeWeight: 1,
  clicable: false,
});
AOI.setMap(mapRef.current);

var bounds = new window.google.maps.LatLngBounds();
bounds.current = new window.google.maps.LatLngBounds();

AOI.current = AOI;
AOI.current
  .getPath()
  .getArray()
  .forEach((coord) => {
    let latLng = new window.google.maps.LatLng(coord.lat(), coord.lng());
    bounds.current.extend(latLng);
  });
const center = bounds.current.getCenter(); 
mapRef.current.setCenter(center);
mapRef.current.panBy(1000, 0);

console.log("bounds", bounds);





var images = [img0,img1, img2, img3, img4, img5, img6, img7,  img8, img9, img10];

geometryThumb = ee.Geometry.Polygon(geometryThumb);
let mission = JSON.parse(window.localStorage.getItem("selectedMission"));
console.log("mission", mission);
let thumbnailParams ={region: geometryThumb,bands:[mission.bands.red,mission.bands.green,mission.bands.blue],dimensions: 512,max:mission.vizParams.max, min:mission.vizParams.min, format: 'jpg'};       
            
   //new code to add layers
   useEffect(() => {
    let values = Object.values(images); 
    values = values.map((value) =>{ 
      let thumbnailURL = value.getThumbURL(thumbnailParams);
      return {
              date: "2023", 
              falseColorUrl: thumbnailURL,
              imageObject: value, 
              missionName: "COPERNICUS/S2_SR_HARMONIZED",
              name: "2023",
              url: thumbnailURL               
            };
    });
    if (values.length > 0  && imagesLayers.length === 0){
      values.forEach((image, i) => {
        (() => {
         //console.log("Thumbnail params:", thumbnailParams);
          const imgOverlay = new Image();
          imgOverlay.src = image.url;
          const imgFalseColor = new Image();
          imgFalseColor.src = image.falseColorUrl;
        })();
        console.log("(layers)image", image);
        console.log("window.google.maps.",window.google.maps);
        

        var overlayFalseColor = new window.google.maps.GroundOverlay(
          image.falseColorUrl,
          bounds.current
        );

        var overlay = new window.google.maps.GroundOverlay(
          image.url,
          bounds.current
        );
        console.log("overlay", overlay,"img.url", image.url);
        console.log("mapRef", mapRef, mapRef.current);
        //overlayFalseColor.setMap(mapRef.current);
        overlay.setMap(mapRef.current);
        
        //overlayFalseColor.setMap(null);
        if (i !== 0) {
          //overlay.setMap(null);
        }
        console.log("set image layers....",imagesLayers, overlay, overlayFalseColor);
        setImagesLayers({
          ...imagesLayers,
          [image.date]: { ...image, overlay, overlayFalseColor },
        });
        console.log("set layers...");
        setLayers({
          ...layers,
          [image.date]: { ...image, overlay, overlayFalseColor },
        });

        markedPoints.current = { [image.date]: [] };
      });
      ///setTabKey(values[0].date ?? 0);
      console.log("End layers calculation:", layers, imagesLayers);
      window.layers = layers;
      window.imagesLayers = imagesLayers;
    }else{
      console.log("layers already calculated:", layers, imagesLayers);
      window.layers = layers;
      window.imagesLayers = imagesLayers;
      if(layers !== undefined && layers["2023"] !== undefined ){
        let layer = layers["2023"];
        console.log("Display layers..",layer);
        //layers.forEach(layer => {
            //display layer
            if (layer.classification) {
              layer.classification.setMap(mapRef.current);
            } else if (false) {
              layer.overlayFalseColor.setMap(mapRef.current);
            } else {
              
              if (window.layersCalculated !== undefined){
                console.log("set layer calculated....", window.layersCalculated ); 
                window.layersCalculated.forEach( el=>{
                  el.setMap(mapRef.current); 
                }); 
              }else{
                console.log("set layer mock data....");
                layer.overlay.setMap(mapRef.current); 
              }
            }
/*
            if (
              markedPoints.current[layer.date] &&
              markedPoints.current[layer.date].length > 0 &&
              !layers[layer.date].classification
            )
            markedPoints.current[layer.date].forEach((polyg) => {
              console.log("markedPoints",polyg);
              if (polyg) polyg.overlay.setVisible(true);
            });
            */
        }
     // });

    }
  }  , [images]);
 
  //end: new code to add layers



  function getCoordinates(shape) {
    let coordinates = [];

    if (shape.type === "rectangle") {
      const bounds = shape.overlay.getBounds();
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();
      coordinates = [
        [ne.lng(), ne.lat()],
        [ne.lng(), sw.lat()],
        [sw.lng(), sw.lat()],
        [sw.lng(), ne.lat()],
        [ne.lng(), ne.lat()],
      ];
    }

    if (shape.type === "polygon") {
      const path = shape.overlay.getPath();
      const array = path.getArray();
      coordinates = array.map((p) => [p.lng(), p.lat()]);
    }

    return coordinates;
  }


  
  // handle drawing the AOI on the map
  const handleDrawing = (overlay, coordinates) => {
    setOverlay(overlay);
    setCoordinates(coordinates);
    window.localStorage.setItem("AOIIntertidal",JSON.stringify(coordinates));
    // disable the drawing controls, when the AOI is drawn
    MapGoogle.setDrawingControlsVisible(false);
  };


  // handle the undoing of the AOI
  const handleUndo = () => {
    showThresholdDialog();
    /*
    overlay.setMap(null);
    setOverlay(null);
    setCoordinates(null);
    // enable the drawing controls
    MapGoogle.setDrawingControlsVisible(true);
    */
  };

  // handle the saving of the AOI
  const handleChoose = () => {
    try{
      
        dispatch(

          Acquisition.setAOI(
            overlay,
            coordinates,
            ee.Geometry.Polygon([coordinates])
          )
        );
        
    }catch(e){
      window.alert("Your account still is not allowed to use GEE API. You should complete your register using this link: https://earthengine.google.com/signup/");
      window.location = "https://earthengine.google.com/signup/";
    }
    try{
     
      
    }catch(e){
      console.error("Error image filter", e); 
    } 


  };


  // defines the steps for the tour
  const steps = [
    {
      selector: "#areachooser",
      content: t("tour.csqueeze.2.aoi"),
    },
    {
      selector: "#mangrove",
      content: t("tour.csqueeze.2.mangrove"),
    },
    {
      selector: "#importKml",
      content: t("tour.csqueeze.2.importKml"),
    },
  ];

  // create a localStorage object to check if the user has already seen the tour
  const [isTourOpen, setIsTourOpen] = useLocalStorage("showROITour", true);

//step 4 - init
console.log("STEP 4", "User filtered images:", dates); 
let eeImageList = dates.map(el => ee.Image(el.id));

//let imageCollection = ee.ImageCollection.fromImages(eeImageList);



// Create an image collection from the images
let  imageCollection = ee.ImageCollection.fromImages([img0,img1, img2, img3, img4, img5, img6, img7, img8, img9, img10]);  



console.log("Image Collection", imageCollection.getInfo());
let coordinatesArray =  JSON.parse(window.localStorage.getItem("AOIIntertidal"));
console.log("coordinates", coordinatesArray);
let geometry = ee.Geometry.Polygon(coordinatesArray); 
console.log("geometry", geometry); 
// Mask out land and clouds
function mask(image) { 
   let imgMasked =  maskS2ImageMask(image, mission); 
   let ratio = maskS2Clouds(imgMasked, geometry, mission.bands.qa, mission.opticalResolution );
   return imgMasked.set("CLOUDS", ratio);
}

var Bay_Plenty_collection = imageCollection;
Bay_Plenty_collection = Bay_Plenty_collection.map(mask);

console.log ('initial collection', Bay_Plenty_collection.getInfo());

function reflec_corr (image){
  var opticalBands = image.select('B.*').multiply(0.0001); //applying the scale factor of Sentinel-2 collection
    return image
    .addBands(opticalBands, null, true);
  }

var Bay_Plenty_collection = Bay_Plenty_collection.map(reflec_corr);
console.log ('scaled collection',Bay_Plenty_collection);

var vis_rgb = {
  max: 0.25,
  bands: ['B4', 'B3', 'B2'], // to visualize in true color
  };

var singleBandVis = {
              'min': -0.5,
              'max': 1,
              };

//Map.addLayer(Bay_Plenty_collection.first(), vis_rgb, "first image masked");

///// PANSHARPENING
var geeSharp = require('./../../../common/geeSharp/sharpeners'); // Import the geeSharp module
var entry = require('./../../../common/geeSharp/entry'); 
console.log("entry.sharpen",entry.sharpen); 
console.log("geeSharp", geeSharp );
// After analysing the charts, choose the band that showed the bigest r^2
function sharpened (image) {
  var sharp1 = entry.sharpen(image.select(['B11']), image.select(['B8'])).rename ('B11_sharp');
  var sharp2 = entry.sharpen(image.select(['B12']), image.select(['B8'])).rename ('B12_sharp');
  
  return image.addBands([sharp1,sharp2])
}

var Bay_Plenty_collection = Bay_Plenty_collection.map(sharpened);
console.log('sharpened collection',Bay_Plenty_collection, Bay_Plenty_collection.getInfo());

//// NDWI e outros indices
var NDWIindex = function(image) {
 var ndwi = image.normalizedDifference(['B3', 'B8']).rename ('ndwi'); //Mc Feeters, 1996
 var mndwi_sharp = image.normalizedDifference(['B3', 'B11_sharp']).rename ('mndwi_sharp');
 var mndwi = image.normalizedDifference(['B3', 'B11']).rename ('mndwi');
 return image.addBands([ndwi, mndwi_sharp,mndwi]);
};

var AWEIindex = function(image) {
   var awei = image.expression('(B+ (2.5*G) -1.5*(N+S1) -(0.25*S2)) ',{ //Feyisa etal, 2014 (4 * (G - S)) - (0.25 * N + 2.75 * S)
   B: image.select('B2'),
   G: image.select('B3'), 
   S1: image.select('B11_sharp'), 
   S2: image.select('B12_sharp'),
   N: image.select('B8'),
   }).rename('awei');
   return image.addBands([awei]);
};


var clip_image = function (image){
  return image.clip(geometry)
}; // corta para a área de interesse
  
var mask_land = function (image){
  var ndwi = image.select('mndwi_sharp');
  return image.updateMask(ndwi.gte(-0.2))
}; // deixa apenas valores maiores ou iguais a 0.2 //-0,2 para macromaré


///// APLICANDO AS FUNÇÕES E CORTANDO NA GEOMETRIA
var NWI = Bay_Plenty_collection.map(NDWIindex);// calcula o NDWI;  
var NWINoMask=NWI.map(clip_image); // Faz o corte

//// MÁSCARA DE ÁGUA/TERRA
var NWI=NWINoMask.map(mask_land);  //// aplica máscara a aprtir do valor do histograma
//Map.addLayer(NWI.first().select('mndwi_sharp'),singleBandVis, "NDWI-Masked");
//Map.addLayer(NWINoMask.first().select('mndwi_sharp'),singleBandVis, "NDWI-No Masked",false);

//// APLICAÇÃO AWEI
var awei = NWI.map(AWEIindex);
var aweiVis = {
   'max': 0.2,
   'min': -0.2,
};
//Map.addLayer(awei.first().select('awei'),aweiVis, "AWEI index");
/*var awei2 = NWINoMask.map(AWEIindex);
Map.addLayer(awei2.first().select('awei'),aweiVis, "AWEI sem mascara index");
*/
console.log('pós AWEI', awei, awei.getInfo());
/*
// Visualizar AWEI histograma
var hist_NDWI_Mask = ui.Chart.image.histogram({image:awei.first().select('mndwi_sharp'), region: geometry, scale: 11})
  .setOptions({title: 'Histograma NDWI com máscara'});
//print (hist_NDWI_Mask);

var palette = ['blue','yellow'];
var vis_params = {
              'min': -1,
              'max': 1,
              'dimensions': 500,
              'palette':palette,             
              };

/*
var hist_AWEI_Mask = ui.Chart.image.histogram({image:awei.first().select('awei'), region: geometry, scale: 11})
  .setOptions({title: 'Histograma AWEI com máscara'});
//print (hist_AWEI_Mask);
*/
var NWI_STD = awei.select('awei').reduce(ee.Reducer.stdDev()); // Agora só tem uma imagem que mostra o STD dos NDWI
console.log("NWI_STD",NWI_STD.getInfo());
//Map.addLayer(NWI_STD, singleBandVis,'STD image');
/*
var hist_NWI_STD = ui.Chart.image.histogram({image:NWI_STD, region: geometry, scale: 11})
  .setOptions({title: 'Histograma AWEI STD'});
//print (hist_NWI_STD);
*/
// UTILIZANDO A METODOLOGIA DE THRESHOLD OTSU:
var histogram = NWI_STD.reduceRegion({
  reducer: ee.Reducer.histogram(),
  geometry: geometry, 
  scale: 10
});
console.log("histogram", histogram.getInfo()); 
var AWEI_stdDev = histogram.get('awei_stdDev');
console.log("AWEI_stdDev", AWEI_stdDev.getInfo());
//print(AWEI_stdDev);
var otsu = function(histogram) {
  var counts = ee.Array(ee.Dictionary(AWEI_stdDev).get('histogram'));
  var means = ee.Array(ee.Dictionary(AWEI_stdDev).get('bucketMeans'));
  var size = means.length().get([0]);
  var total = counts.reduce(ee.Reducer.sum(), [0]).get([0]);
  var sum = means.multiply(counts).reduce(ee.Reducer.sum(), [0]).get([0]);
  var mean = sum.divide(total);

  var indices = ee.List.sequence(1, size);
  // Compute between sum of squares, where each mean partitions the data.
  var bss = indices.map(function(i) {
    var aCounts = counts.slice(0, 0, i);
    var aCount = aCounts.reduce(ee.Reducer.sum(), [0]).get([0]);
    var aMeans = means.slice(0, 0, i);
    var aMean = aMeans.multiply(aCounts)
        .reduce(ee.Reducer.sum(), [0]).get([0])
        .divide(aCount);
    var bCount = total.subtract(aCount);
    var bMean = sum.subtract(aCount.multiply(aMean)).divide(bCount);
    return aCount.multiply(aMean.subtract(mean).pow(2)).add(
          bCount.multiply(bMean.subtract(mean).pow(2)));
  });

  //print(ui.Chart.array.values(ee.Array(bss), 0, means));
  // Return the mean value corresponding to the maximum BSS.
  return means.sort(bss).get([-1]);
};
var threshold = null;
if (manualThreshold === -1){
  threshold = otsu(histogram.get('histogram'));
  //print('the threshold is:    ', threshold);
  console.log("threshold", threshold.getInfo());
  window.sessionStorage.setItem("thresholdIntertidal", threshold.getInfo());
}else{
  threshold = ee.Number(Number.parseFloat(manualThreshold));
}

///// Máscara a partir do histograma do desvio pasdrão
// valores maiores que X*STD (retirar X do histograma do NWI_STD)
var stdMasked = NWI_STD.updateMask(NWI_STD.gte(threshold)); //EQ("="), GTE(">="), GT(">"), LT("<"), LTE("<="); // Threshold para Barra do Sul: 0.05
var zones=NWI_STD.gte(threshold); //threshold is mean value from the histogram
var zones = zones.updateMask(zones.neq(0));
//print("zones...", zones);
console.log("zones", zones.getInfo());
//Map.addLayer(zones, vis_params, 'stdMasked', false); 
// print("zones", zones)
// Visualizar histograma pós corte
var hist_intermare = stdMasked.reduceRegion({
  reducer: ee.Reducer.histogram(),
  geometry: geometry, 
  scale: 10
});
/*
var hist_intermare = ui.Chart.image.histogram({image:stdMasked, region:geometry, scale:11})
  .setOptions({title: 'Histograma intermaré'});
//print (hist_intermare);
*/
///// Transformar para vetores
var vectors = zones.addBands(NWI_STD).reduceToVectors({ 
  crs: 'EPSG:4326',
  //crsTransform: [60, 0, 399960, 0, -60, 5900020],
  scale: 10,
  geometryType:'polygon',
  labelProperty: 'stdMasked',
  eightConnected: false,
  geometry: geometry,
  maxPixels: 100e9,
  geometryInNativeProjection: true,
  reducer: ee.Reducer.mean()
});
//print("número de vetores identificados: ", (vectors.getInfo())); 
//console.log("número de vetores identificados: ", (vectors.getInfo()));
///// Função para cortar de acordo com os vetores
var clip_image2 = function(image){
  return image.clip(vectors);
};

//var NWI2 = Bay_Plenty_collection.map(AWEIindex).select('awei'); //para exportar img com AWEI
var NWI2 = Bay_Plenty_collection.map(NDWIindex).select('ndwi');
console.log("NWI2: ", (NWI2.getInfo()));
//print("Clip das imagens", NWI2);
var intertidal_zones = NWI2.map(clip_image2);
//print(intertidal_zones)
// Displaying

var vis_params = {
  'min': -0.5,
  'max': 1,
  'region': geometry,
  'dimensions': 512,
  'palette':['white','blue'],     
  /*format: 'jpg'*/    
  };
//console.log("intertidal_zones", intertidal_zones.getInfo());    
//let url = intertidal_zones.first().getThumbURL(vis_params);   
//console.log("intertidal_zones (URL)", url);
console.log("calling .map...", intertidal_zones);
window.layersCalculated = [];



var n = intertidal_zones.getInfo().features.length;
var list = intertidal_zones.toList(n,0);
var i = 0;

console.log("LAYER: bounds.current", bounds.current);
for(i=0;i<n;i++){
  var img= ee.Image(list.get(i));
  
   //console.log("img", img, img.getInfo());
    let url = img.getThumbURL(vis_params); 
   // img.url = url
    console.log("creating layer for", url);
   
    let overlayIntertidalZone = new window.google.maps.GroundOverlay(
    url,
    bounds.current 
  );
  overlayIntertidalZone.setMap(mapRef.current); 
  window.layersCalculated.push(overlayIntertidalZone); 
};
console.log("calling map end..",intertidal_zones);


//Map.addLayer(intertidal_zones, vis_params, 'intertidal zone');

//// Exportar vetores
/*
var task = Export.table.toDrive({
  collection: vectors,
  description:'Intertidal_Zones_aweiValeSand',
  folder: 'Intertidal_Zones',
  fileFormat: 'SHP'});
  
//// Exportar imagens
var ExportCol = function (col, folder, scale, tp, maxPixels, region) {
  scale = 10,
  maxPixels =  100e9;
  
  var nimg=col.size().getInfo();
  var colList = col.toList(nimg);
  var n = colList.size().getInfo();

  for (var i = 0 ; i < n; i++) {
    var img = ee.Image(colList.get(i));
    var id = img.get('system:id').getInfo() //.id().getInfo();
    region = region; // img.geometry().bounds().getInfo()["coordinates"];

      var imgtype = {"float":img.toFloat(), 
                 "byte":img.toByte(), 
                 "int":img.toInt(),
                 "double":img.toDouble()};

      Export.image.toDrive({
        image:imgtype[tp],
        description: id,
        folder: folder,
        fileNamePrefix: id + "_ndwi", 
        crs: 'EPSG:4326',
        region: region,
        scale: scale,
        maxPixels: maxPixels}
        );
        
      Export.image.toAsset({
        image:imgtype[tp],
        description: 'asset_'+id,
        assetId: 'projects/ee-index-images/assets/valeSand/'+id,
        crs:'EPSG:4326',
        region: region,
        scale: scale,
        maxPixels: maxPixels}
        )}
};
var task2 = ExportCol(intertidal_zones, 'Intertidal_Zones', 10, 'float', 100e9, geometry);


Export.image.toDrive({
  image: NWI_STD, 
  description:'Zones_AWEI_ValeSand',
  folder: 'Intertidal_Zones', 
  crs: 'EPSG:4326',
  fileNamePrefix:'awei_std_ValeSand',
  region:geometry, 
  scale: 10,
  maxPixels: 100e9});
*/

//end

const showThresholdDialog = function (){
  let dialog = document.getElementById("change_threshold");
  document.getElementById("threshold").value =   window.sessionStorage.getItem("thresholdIntertidal"); 
  dialog.showModal();
}

const closeThresholdDialog = function (){
  let dialog = document.getElementById("change_threshold");
  dialog.close();
}

const redoThreshold = function (){
  const threshold = document.getElementById("threshold").value;
  window.sessionStorage.setItem("manualThreshold", threshold);
  closeThresholdDialog();
  setManualThreshold(threshold); //update screen
}

//start: histogram graph parameters
const labels = [0.75, 1.25, 1.75, 2.25];
const data = [1, 2, 3, 4];
const optionsBarChart = {
  pan: {
    enabled: true,
    mode: "xy"
  },
  zoom: {
    enabled: true,
    drag: false,
    mode: "xy"
  },
  scales: {
    x: {
      type: "linear",
      offset: false,
      gridLines: {
        offsetGridLines: false
      },
      title: {
        display: true,
        text: "Arrivals per minute"
      }
    }
  },
  onClick: function(evt, element) {
    console.log("Chart clicked", element);
    if(element.length > 0){
      var index = element[0]._index;
      console.log("Point clicked:", labels[index], data[index]);
      document.getElementById("threshold").value = labels[index];
    }
  },

};

const plugins = [
  {
    afterDraw: (chart) => {
      // eslint-disable-next-line no-underscore-dangle
      if (chart.tooltip._active && chart.tooltip._active.length) {
        // find coordinates of tooltip
        const activePoint = chart.tooltip._active[0];
        const { ctx } = chart;
        const { x } = activePoint.element;
        const topY = chart.scales.y.top;
        const bottomY = chart.scales.y.bottom;

        // draw vertical line
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(x, topY);
        ctx.lineTo(x, bottomY);
        ctx.lineWidth = 1;
        ctx.strokeStyle = '#1C2128';
        ctx.stroke(); 
        ctx.restore();
      }
    },
  },
];

let dataBarChart = {
  labels: labels,
  datasets: [
    {
      label: 'Dataset 1',
      borderColor: "blac",
      lineTension: 0,
      fill: false,
      borderJoinStyle: "round",
      data: data, 
      borderWidth: 0.2,
      barPercentage: 1,
      categoryPercentage: 1,
      hoverBackgroundColor: "darkgray",
      barThickness: "flex"
    },
    /*
    {
      label: 'Dataset 2',
      data: [0, 4, 3, 0],
      borderColor: "blue",
      backgroundColor: "#FFF",
      type: 'line',
      order: 0
    }
      */
  ]
};
//end: histogram graph parameters


  return (
    <React.Fragment>
      <dialog id="change_threshold" style={{width:400, height:400}}>
        <div>
          <Bar  options={optionsBarChart} data={dataBarChart} plugins={plugins}  />
        </div>

        <label for="threshold">Threshold</label> <br/>
        <input type="text" id="threshold" /><br/>
        <input type="button" value="REDO" onClick={redoThreshold} />&nbsp;
        <input type="button" value="CANCEL" onClick={closeThresholdDialog} />
      </dialog>

      <Box>

        <HelpButton
          onClickFunction={() => {
            setIsTourOpen(true);
          }}
        />
        <GoogleMap
          style={{ width: "100%", height: 500 } /* @TODO pass as props */}
          onRegionDrawn={handleDrawing}
        />

        <StepperButtons
          navigate={navigate}
          onNext={handleChoose}
        >
          <Button
            onClick={handleUndo}
            color="secondary"
            variant="contained"
          >
            {t("forms.intertidal.4.returnAndRedo")}
          </Button>

        </StepperButtons>
        {
          // if the user hasn't seen the tour, show it
        }
        <TourGuider
          steps={steps}
          isOpen={isTourOpen}
          setIsTourOpen={setIsTourOpen}
        />
      </Box>
    </React.Fragment>
  );
};

export default IntertidalZoneDefiner;
